import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import ProgressBar from "./../ProgressBar/ProgressBar";
import Loader from "../Loader";
import { getResultData } from "./../../actions/resultActions";
import "./result.css";
import PieChart from "./PieChart";
import MarksBar from "../ProgressBar/MarksBar";
import TypingAnimation from "./TypingAnimation";

const Results = ({ history, match }) => {
  const adviceRef = useRef(null);
  const alert = useAlert();
  const dispatch = useDispatch();

  const { user, loading } = useSelector((state) => state.auth);
  const { rLoading, result } = useSelector((state) => state.results);
  const userId = user._id;
  const quizId = match.params.quizId;

  useEffect(() => {
    dispatch(getResultData(quizId));
  }, [dispatch, quizId, alert]);

  const calculateProgress = (attend, total) => (
    (parseInt(attend) * 100) / parseInt(total)
  ).toFixed(1);

  const calculateAccuracy = (correct, total) => (
    (parseInt(correct) / parseInt(total)) * 100
  ).toFixed(1);

  const calculateSpeedPercent = (totalTime, usedTime) => {
    const tt = totalTime.split(":").reduce((acc, val, idx) => acc + parseInt(val) * Math.pow(60, 2 - idx), 0);
    const ut = usedTime.split(":").reduce((acc, val, idx) => acc + parseInt(val) * Math.pow(60, 2 - idx), 0);
    const dtime = tt - ut;
    return ((dtime / tt) * 100).toFixed(2);
  };


  const getAdvice = (accuracy, advices) => {
    let advice = "";
    let adviceDescription = "";
    advices.some((item) => {
      if (parseFloat(accuracy) >= item.min_percentage && parseFloat(accuracy) <= item.max_percentage) {
        advice = item.title;
        adviceDescription = item.description;
        return true;
      }
      return false;
    });
    return { advice, adviceDescription };
  };

  const { progress, accuracy, speedPercent, jambScore, color, advice, adviceDescription } = useMemo(() => {
    if (result?.quiz) {
      const totalQuestions = result.quiz.questionCount;
      const attendQuestion = totalQuestions - result.quiz.unseenCount;
      const correctAnswers = result.quiz.correctCount;

      const progress = calculateProgress(attendQuestion, totalQuestions);
      let accuracy = calculateAccuracy(correctAnswers, totalQuestions);
      let jambScore = "";
      let color = "";

      if (result.quiz.exam.slug === "jamb-utme" && result.quiz.assessment.slug === "mock") {
        jambScore = Math.round((correctAnswers / totalQuestions) * 400);
        if (jambScore <= 199) {
          color = "red";
        } else if (jambScore >= 200 && jambScore <= 299) {
          color = "yellow";
        } else if (jambScore >= 300 && jambScore <= 400) {
          color = "green";
        }
      }

      const speedPercent = calculateSpeedPercent(result.quiz.time, result.quiz.usedtime);
      const { advice, adviceDescription } = getAdvice(accuracy, result.advices);

      return { progress, accuracy, speedPercent, jambScore, color, advice, adviceDescription };
    }
    return {};
  }, [result]);

  useEffect(() => {
    const adjustFontSize = () => {
      const adviceElement = adviceRef.current;
      if (adviceElement) {
        let fontSize = 1.8; // Initial font size in vw
        adviceElement.style.fontSize = `${fontSize}vw`;

        while (adviceElement.scrollWidth > adviceElement.clientWidth && fontSize > 0.5) {
          fontSize -= 0.2;
          adviceElement.style.fontSize = `${fontSize}vw`;
        }
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [advice]);


  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {result?.quiz && (
            <div className="right-auth-landing edit-pros">
              <div className="main-view-content">
                <div className="all-ourt-style w-80-cust clearfix">
                  <div className="over-all-results-graph">
                    <h3 className="over-al-head">Results</h3>
                    <div className="row">
                      {["Progress", "Accuracy", "Time"].map((title, index) => (
                        <div key={index} className="col-sm-4">
                          <div className="graph-box">
                            <h3>
                              {title} <span>{index === 0 ? progress : index === 1 ? accuracy : speedPercent}%</span>
                            </h3>
                            <div className="progressbar">
                              <div className="m-auto" style={{ width: 150, height: 150 }}>
                                <ProgressBar
                                  percentage={index === 0 ? progress : index === 1 ? accuracy : speedPercent}
                                  pathColor={index === 0 ? "green" : index === 1 ? "purple" : "blue"}
                                />
                              </div>
                            </div>
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th scope="row">Total Questions</th>
                                    <td className="text-right">{result.quiz.questionCount}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Attend Questions</th>
                                    <td className="text-right">{result.quiz.questionCount - result.quiz.unseenCount}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Skipped Questions</th>
                                    <td className="text-right">{result.quiz.unseenCount}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="all-ourt-style w-80-cust clearfix">
                  <h3 className="over-al-head">Result <span>Completed</span></h3>
                  <div className="row">
                    <div className="col-sm-4">
                      {result.quiz.exam.slug === "jamb-utme" && result.quiz.assessment.slug === "mock" && (
                        <div className="result-boxers">
                          <div className="box-head">
                            <h1>JAMB UTME MOCK SCORE</h1>
                          </div>
                          <div className="progressbar">
                            <div className="m-auto" style={{ width: 160, height: 160 }}>
                              <MarksBar percentage={jambScore} pathColor={color} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-4">
                      <div className="result-boxers">
                        <div className="box-head">
                          <h1>Advice : </h1>
                          <h1><span>{advice}</span></h1>
                        </div>
                        <div className="c100 p100 pink text-20">
                          <span className="font-16px chart-headsVis" ref={adviceRef}>{advice}</span>
                          <div className="slice">
                            <div className="bar"></div>
                            <div className="fill"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="result-boxers">
                        <div className="box-head">
                          <h1></h1>
                        </div>
                        <TypingAnimation text={adviceDescription} typingSpeed={20} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="all-ourt-style w-80-cust clearfix">
                  <h3 className="over-al-head">Subject Result <span>test</span></h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ width: "300px" }}>Subject Name</th>
                          <th style={{ width: "300px" }}>Correct</th>
                          <th style={{ width: "300px" }}>Incorrect</th>
                          <th style={{ width: "300px" }}>Un Answer</th>
                          <th style={{ width: "300px" }}>Accuracy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result.subjectResults.map((item) => {
                          const unAnswered = item.quesLength - (item.rightAnswer + item.wrongAnswer);
                          const subjectAccuracy = result.quiz.exam.slug === "jamb-utme" && result.quiz.assessment.slug === "mock"
                            ? ((item.rightAnswer / 180) * 400).toFixed(1)
                            : ((item.rightAnswer / item.quesLength) * 100).toFixed(1);
                          return (
                            <tr key={item._id}>
                              <td>{item.subject.title}</td>
                              <td>{item.rightAnswer}/{item.quesLength}</td>
                              <td>{item.wrongAnswer}/{item.quesLength}</td>
                              <td>{unAnswered}/{item.quesLength}</td>
                              <td>{subjectAccuracy}%</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {result.quiz && (
                  <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                    <PieChart
                      correctAnswers={result.quiz.correctCount}
                      incorrectAnswers={result.quiz.wrongCount}
                      unseenCount={result.quiz.unseenCount}
                    />
                  </div>
                )}
                <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                  <div className="pull-left">
                    <Link to={`/results`} className="btn view-ans-btn">
                      Back
                    </Link>
                  </div>
                  <div className="pull-right">
                    <Link
                      to={{
                        pathname: `/practice-results-view/${result.quiz._id}`,
                        search: `?assessmentTitle=${encodeURIComponent(result.quiz.assessment.title)}`,
                      }}
                      className="btn view-ans-btn"
                    >
                      View Corrections
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Results;
