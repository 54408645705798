import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./../../assets/frontend/css/bootstrap.min.css";
import "./../../assets/frontend/css/owl.carousel.css";
import "./../../assets/frontend/css/owl.theme.css";
// import "./../../assets/frontend/css/font-awesome.min.css";
import "./../../assets/frontend/css/animated.css";
import "./../../assets/frontend/css/icon-ots.css";
// import "./../../assets/frontend/css/main.css";
// import "./../../assets/frontend/css/responsive.css";

const Header = () => {
    const { user, loading } = useSelector((state) => state.auth);

    useEffect(() => {
        const cssFiles = [
          'assets/frontend/css/font-awesome.min.css',
          'assets/frontend/css/main.css',
          'assets/frontend/css/responsive.css'
        ];
    
        cssFiles.forEach((file) => {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = `${process.env.PUBLIC_URL}/${file}`;
          document.head.appendChild(link);
        });
    
        // Cleanup function to remove links when component is unmounted
        return () => {
          cssFiles.forEach((file) => {
            const link = document.querySelector(`link[href="${process.env.PUBLIC_URL}/${file}"]`);
            if (link) {
              document.head.removeChild(link);
            }
          });
        };
      }, []);

    const handleExamClick = () => {
        localStorage.removeItem("examId");
        localStorage.removeItem("examName");
        localStorage.removeItem("categoryId");
        localStorage.removeItem("courseId");
        localStorage.removeItem("subjects");
        localStorage.removeItem("assessmentId");
        localStorage.removeItem("assessmentName");
        localStorage.removeItem("assessmentSlug");
        localStorage.removeItem("checkedSubjects");
    };

    const [isActive, setActive] = useState(false);

    const handleToggleMenu = () => {
        setActive(!isActive);
    };

    /*useEffect(() => {
        setActive(false);
    }, []);*/

    return (
        <header className={isActive ? "menu-open" : null}>
            <nav className="custom-nav clearfix">
                <div className="logo">
                    <Link to="/">
                        <img
                            src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/logo.png"}
                            alt="SITE-LOGO"
                        />
                    </Link>
                </div>
                <div className="main-nav pull-right">
                    <ul onClick={(e) => setActive(false)}>
                        <li className="active">
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/exams" onClick={handleExamClick}>
                                Examination
                            </Link>
                        </li>
                        <li>
                            <Link to="/pages/about-us">About Us</Link>
                        </li>
                        <li>
                            <Link to="/news">News</Link>
                        </li>
                        <li>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>

                        {user ? (
                            <li className="log-reg mar-left-nav">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                        ) : (
                            <Fragment>
                                <li className="log-reg mar-left-nav">
                                    <Link to="/register">Register</Link>
                                </li>
                                <li className="log-reg" style={{ marginLeft: "5px" }}>
                                    <Link to="/login">Login</Link>
                                </li>
                            </Fragment>
                        )}
                    </ul>
                </div>
                <div className="nav-togg" onClick={handleToggleMenu}>
                    <span></span>
                </div>
            </nav>
        </header>
    );
};

export default Header;
