import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useDispatch, useSelector } from "react-redux";
import { getAllExamTypes } from "./../../actions/commonActions";

import Loader from "../Loader";

const HomeCategory = (props) => {
  let history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllExamTypes());
  }, [dispatch]);

  const { allExamTypes, eloading } = useSelector((state) => state.commonExams);

  const handleCatClick = (itemData) => {
    // e.preventDefault();
    localStorage.setItem("examId", itemData._id);
    localStorage.setItem("examSlug", itemData.slug);
    localStorage.setItem("maximumSubjects", itemData?.maximumSubjects);
    localStorage.setItem("examName", itemData.title);
    if (itemData.examType === "both") {
      history.push(`/exam-type`);
      return;
    } else if (itemData.slug === "post-utme") {
      history.push(`/university`);
      return;
    } else {
      history.push(`/categories`);
      return;
    }
  };

  return (
    <section
      className="exam-category-sec"
      style={{ backgroundImage: `url("../assets/frontend/img/gray-bg.png")` }}
    >
      <div className="container">
        <div className="section-head">
          {/* <h6>Sub Heading</h6> */}
          <h1>{props.heading}</h1>
          <p>{props.subheading}</p>
        </div>
        <div className="exam-cate-slide">
          {eloading ? (
            <Loader />
          ) : (
            <OwlCarousel
              className="owl-carousel owl-theme owl-loaded"
              responsive={{
                0: { items: 1 },       // 1 item for mobile screens
                600: { items: 2 },     // 2 items for tablets
                1000: { items: 4 }     // 4 items for desktops
              }}
              margin={20}
            >
              {allExamTypes !== undefined && allExamTypes.length > 0
                ? allExamTypes.map((item, key) => {
                  const indexId = key + 1;
                  return (
                    <Fragment key={indexId}>
                      <div className="item">
                        <div className="exam-logos">
                          <div className="vh-center">
                            <img
                              src={
                                process.env.REACT_APP_PUBLIC_URL +
                                "/assets/img/exams/" +
                                item.logo
                              }
                              alt="exam-logo"
                            />
                            <h4>{item.title}</h4>
                            {item?.status? (
                            <button
                              type="button"
                              onClick={() => handleCatClick(item)}
                              className="chose-more"
                            >
                              <i className="fas fa-arrow-right"></i>
                            </button>
                            ):(
                              <p><b>Coming Soon</b></p>
                            )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
                : ""}
            </OwlCarousel>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeCategory;
