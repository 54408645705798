import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import moment from "moment";
import ReactHtmlParser from "react-html-parser"

import Countdown, {
    zeroPad,
    calcTimeDelta,
    formatTimeDelta,
} from "react-countdown";

import Loader from "../Loader";

import { getYearQuestions } from "./../../actions/questionActions";

import { submitExamResult } from "./../../actions/resultActions";
import { SUBMIT_RESULT_RESET } from "./../../constants/resultConstants";

import "./question.css";

import Modal from "../Modal/Modal";
import Calculator from "../Calculator/Calculator";

const ObjectiveQuestions = ({ history }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const { user, loading, error } = useSelector((state) => state.auth);

    const userId = user._id;

    const examId = localStorage.getItem("examId");
    const examType = localStorage.getItem("examType");
    const subjectId = localStorage.getItem("subjectId");
    const subjectIds = localStorage.getItem("checkedSubjects")?.split(",");
    const year = localStorage.getItem("year");
    const shuffleQuestions = localStorage.getItem("shuffleQuestions");
    const shuffleOptions = localStorage.getItem("shuffleOptions");
    const timeDuration = localStorage.getItem("timeDuration");
    // alert.info(timeDuration);
    //var timerData = Date.now() + parseInt(timeDuration) * 60 * 1000;
    const assessmentId = localStorage.getItem("assessmentId");
    const assessmentType = localStorage.getItem("assessmentName");
    const savedSubjectId = localStorage.getItem("savedId");
    const noOfQuestion = localStorage.getItem("noOfQuestion");
    const selectedTopics = localStorage.getItem("selectedTopics")
    const selectedUniversity = localStorage.getItem("selectedUniversity")
    const objectIdArray = selectedTopics && selectedTopics.split(',').map(id => id.trim());
    const objectIdArray1 = selectedUniversity && selectedUniversity.split(',').map(id => id.trim());

    const { rLoading, isSubmitted, result } = useSelector(
        (state) => state.results
    );
    
    function disableF5(e) {
        if ((e.which || e.keyCode) == 116 || (e.ctrlKey && e.keycode == 82)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }


    useEffect(() => {
        window.addEventListener("keydown", disableF5);

        window.history.pushState(null, document.title, window.location.href);
        window.history.back();
        window.history.forward();
        window.onpopstate = function () {
            history.go(1);
        };


        let subTopics = [];
        objectIdArray?.map((item) => subTopics.push(item));

        let university = [];
        objectIdArray1 && objectIdArray1?.map((item) => university.push(item));

        const questionCount = localStorage.getItem('questionCount');
        const subscriptionId = localStorage.getItem("userSubscription");
                
        dispatch(
            getYearQuestions(
                examId,
                examType,
                subjectIds,
                year,
                shuffleQuestions,
                shuffleOptions,
                subTopics,
                questionCount,
                university,
                subscriptionId
            )
        );
    }, [dispatch, alert]);

    const { examData, questions, questionCount, qLoading } = useSelector(
        (state) => state.yearQuestions
    );

    const [showCalculator, setShowCalculator] = useState(false);

    // console.log("questions ", questions); return
    const [totalQuestions, setTotalQuestions] = useState(0);

    const [currentSubject, setCurrentSubject] = useState(0);

    const [questionType, setQuestionType] = useState("MCQ");

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);

    const [unanswered, setUnanswered] = useState([]);
    const [answered, setAnswered] = useState([]);
    const [markForReviewed, setMarkForReviewed] = useState([]);

    const [theoriesAnswered, setTheoriesAnswered] = useState([]);

    const [showResults, setShowResults] = useState(false);
    const [rightAnswer, setRightAnswer] = useState("");
    const [description, setDescription] = useState("123")

    const [currentOptions, setCurrentOptions] = useState([]);

    // if (assessmentType === "practice") {
    //   timerData = Date.now() + parseInt(timeDuration) * 60 * 1000;
    // }
    const [totalTime, setTotalTime] = useState(
        Date.now() + parseInt(timeDuration) * 60 * 1000
    );
    const [quizTotalTime, setQuizTotalTime] = useState("");
    const [remainingTime, setRemainingTime] = useState("");
    const [takenTime, setTakenTime] = useState("");

    useEffect(() => {
        if (
            questions !== undefined &&
            questions.length > 0 &&
            questions[0].questions !== undefined &&
            questions[0].questions.length > 0
        ) {
            var timerData = Date.now() + parseInt(timeDuration) * 60 * 1000;
                        
            setTotalTime(timerData);

            setTotalQuestions(questionCount);
            setNumberOfQuestions(questions[0].questions.length);
            // alert.success(numberOfQuestions);

            var currentQuesOptions;
            // if (shuffleOptions === true) {
            //     currentQuesOptions = questions[0].questions[0].options.sort(() => Math.random() - 0.5);
            // } else {
            //     currentQuesOptions = questions[0].questions[0].options;
            // }            
            //setCurrentOptions(currentQuesOptions);
        }
    }, [questions, rightAnswer]);

    // const [selections, setSelections] = useState([
    //     Object.keys(questions).map((x) => []),
    // ]);

    const initialSelections = questions
        ? Object.keys(questions).map((x) => [])
        : [];

    const [selections, setSelections] = useState(initialSelections);

    // const checkedSubject = localStorage.getItem("checkedSubjects");
    // const yearId = match.params.id;

    // const objectIdArray = checkedSubject.split(',').map(id => id.trim());


    // useEffect(() => {
    //     let temp = [];
    //     objectIdArray?.map((item) => temp.push(item));
    //     dispatch(getQuestionTopicSections(temp));
    // }, [dispatch]);










    const handleSubjectType = (e) => {
        e.preventDefault();
        const currentIndex = parseInt(e.target.dataset.id);

        setQuestionType("MCQ");
        setCurrentSubject(currentIndex);
        setCurrentQuestion(0);
        // alert.success(currentIndex);
        if (
            questions !== undefined &&
            questions.length > 0 &&
            questions[currentIndex].questions !== undefined &&
            questions[currentIndex].questions.length > 0
        ) {
            // alert.success(questions[currentIndex].questions.length);
            setNumberOfQuestions(questions[currentIndex].questions.length);

            // var currentQuesOptions;
            // if (shuffleOptions === true) {
            //     currentQuesOptions = questions[currentIndex].questions[0].options.sort(() => Math.random() - 0.5);
            // } else {
            //     currentQuesOptions = questions[currentIndex].questions[0].options;
            // }
            // setCurrentOptions(currentQuesOptions);
        }
    };

    const onAnswerOptionChange = (event) => {
        const answerId = event.target.value;
        // alert.success(index);
        const selectedQuestionId = event.target.dataset.questionid;

        //remove duplicate
        if (answered.find((obj) => obj.question === selectedQuestionId)) {
            let tempArr = answered;
            let newAns = tempArr.map((obj) => {
               
                if (obj.question === selectedQuestionId && obj.answer !== answerId) {
                    return { ...obj, question: selectedQuestionId, answer: answerId };
                }
                else {
                    return obj
                }
            })
            setAnswered(newAns);
        }
        else {
            let answeredArray = [
                ...answered,
                {
                    question: selectedQuestionId,
                    answer: answerId
                },
            ];
            setAnswered(answeredArray);
        }
        // let answeredArray = [
        //     ...answered,
        //     {
        //         question: selectedQuestionId,
        //         answer: event.target.value,
        //     },
        // ];
        // //remove duplicate answer
        // if (answered.some((ans) => ans.answer === event.target.value)) {
        //     answeredArray = answeredArray.filter(
        //         (item) => item.answer !== event.target.value
        //     );
        // }
        // //remove duplicate question
        // if (
        //     answered.some((ques) => ques.question === selectedQuestionId) &&
        //     answered.some((ques1) => ques1.answer !== event.target.value)
        // ) {
        //     answeredArray = answeredArray.filter((qItem) => {
        //         return (
        //             qItem.question !== selectedQuestionId
        //             // qItem.answer !== event.target.value
        //         );
        //     });
        //     answeredArray = [
        //         ...answeredArray,
        //         {
        //             question: selectedQuestionId,
        //             answer: event.target.value,
        //         },
        //     ];
        // }
        // setAnswered(answeredArray);

        if (selections[answerId]) {
            delete selections[answerId]
        }

        // set selection
        let tempState = { ...selections };
        tempState[answerId] = answerId;
        setSelections(tempState);
    };

    const handleNextQuestionClick = (e) => {
        e.preventDefault();
        const nextQuestion = parseInt(currentQuestion) + 1;
        setCurrentQuestion(nextQuestion);
        setShowResults(false);

        // var currentQuesOptions;
        // if (shuffleOptions === true) {
        //     currentQuesOptions = questions[currentSubject].questions[nextQuestion].options.sort(() => Math.random() - 0.5);
        // } else {
        //     currentQuesOptions = questions[currentSubject].questions[nextQuestion].options;
        // }
        // setCurrentOptions(currentQuesOptions);
    };
    // console.log(currentQuestion, numberOfQuestions);
    const handlePreviousQuestionClick = (e) => {
        e.preventDefault();
        const nextQuestion = parseInt(currentQuestion) - 1;
        setCurrentQuestion(nextQuestion);
        setShowResults(false);

        // var currentQuesOptions;
        // if (shuffleOptions === true) {
        //     currentQuesOptions = questions[currentSubject].questions[nextQuestion].options.sort(() => Math.random() - 0.5);
        // } else {
        //     currentQuesOptions = questions[currentSubject].questions[nextQuestion].options;
        // }
        // setCurrentOptions(currentQuesOptions);
    };

    const handleViewStudyAnswer = (e) => {
        //alert.success(numberOfQuestions);
        e.preventDefault();
        if (
            questions !== undefined &&
            questions.length > 0 &&
            questions[currentSubject].questions !== undefined &&
            questions[currentSubject].questions.length > 0
        ) {
            const currentAns =
                questions[currentSubject].questions[currentQuestion].correctAns;
            const correctAnswer = questions[currentSubject].questions[currentQuestion].options.filter((opItem) => opItem._id === currentAns);

            // console.log("correctAnswer ", questions[currentSubject].questions[
            //     currentQuestion
            //     ]);
            //console.log("currentOptions", questions[currentSubject].questions[currentQuestion].options);
            //console.log("currentAns ", currentAns);
            //setRightAnswer(correctAnswer[0].option);
            setRightAnswer(correctAnswer[0].option);
            setDescription(questions[currentSubject]?.questions[currentQuestion]?.description)
        }
        setShowResults(!showResults);
        // alert.success(rightAnswer);
    };

    const handleFilterQuestion = (e) => {
        e.preventDefault();
        const currentQue = e.target.dataset.id;
        // alert.info(currentQue);
        setCurrentQuestion(currentQue);
        setShowResults(false);

        // var currentQuesOptions;
        // if (shuffleOptions === true) {
        //     currentQuesOptions = questions[currentSubject].questions[currentQue].options.sort(() => Math.random() - 0.5);
        // } else {
        //     currentQuesOptions = questions[currentSubject].questions[currentQue].options;
        // }
        // setCurrentOptions(currentQuesOptions);
    };

    const handleMarkForReview = (event) => {
        let reviewArray = [...markForReviewed, event.target.value];
        if (markForReviewed.includes(event.target.value)) {
            reviewArray = reviewArray.filter((it) => it !== event.target.value);
        }
        setMarkForReviewed(reviewArray);
        // if (event.target.checked === true) {
        //   setDisabled(false);
        // }
        //alert.success("markForReviewed ", markForReviewed);
    };

    const handleRemainigTime = (data) => {
        // totalTime
        var quizHour = Math.floor(timeDuration / 60);
        quizHour = parseInt(quizHour) >= 10 ? quizHour : "0" + quizHour;
        var quizMinutes = timeDuration % 60 === 0 ? "00" : timeDuration % 60;
        var quizSecond = "00";
        const quizTime = quizHour + ":" + quizMinutes + ":" + quizSecond;

        const remainingMinutes =
            data.minutes < 10 ? "0" + data.minutes : data.minutes; // return minutes
        const remainingSeconds =
            data.seconds < 10 ? "0" + data.seconds : data.seconds;

        const remainTime =
            "0" + data.hours + ":" + remainingMinutes + ":" + remainingSeconds;
        var start = moment.duration(quizTime, "HH:mm:ss");
        var end = moment.duration(remainTime, "HH:mm:ss");
        var diff = start.subtract(end);

        const hour = diff.hours(); // return hours
        const minutes = diff.minutes() < 10 ? "0" + diff.minutes() : diff.minutes(); // return minutes
        const seconds = diff.seconds() < 10 ? "0" + diff.seconds() : diff.seconds();
        const usedTime = "0" + hour + ":" + minutes + ":" + seconds;

        setQuizTotalTime(quizTime);
        setRemainingTime(remainTime);
        setTakenTime(usedTime);

        if (remainTime === "00:00:01") {
            alert.info("Time up, your exam is submitting forcefully!!!", {
                onOpen: () => {
                    if (answered.length > 0) {
                        dispatch(
                            submitExamResult(
                                userId,
                                examId,
                                answered,
                                assessmentId,
                                subjectIds,
                                year,
                                totalQuestions,
                                quizTotalTime,
                                remainTime,
                                takenTime,
                                savedSubjectId,
                                examType
                            )
                        );
                    } else {
                        history.push(`/results`);
                    }
                },
            });
        }
    };

    const handleSubmitAnswer = (e) => {
        e.preventDefault();
        if (answered.length > 0) {
            if (
                window.confirm(
                    "Do you really want to submit your answer. We recommend you to review your answer before submitting it."
                )
            ) {
                // console.log("answered ", answered);
                // return;
                dispatch(
                    submitExamResult(
                        userId,
                        examId,
                        answered,
                        assessmentId,
                        subjectIds,
                        year,
                        totalQuestions,
                        quizTotalTime,
                        remainingTime,
                        takenTime,
                        savedSubjectId,
                        examType
                    )
                );
                // history.push(`/dashboard`);
            }
        } else {
            //alert.warning("You have not answered any questions")
            if (
                window.confirm(
                    "You have not answered any questions, your test will be cancelled. do you really want to cancel?"
                )
            ) {
                alert.info("Test has been cancelled.");
                history.push(`/dashboard`);
            }
        }
        return;
    };



    useEffect(() => {
        if (isSubmitted === true && isSubmitted != undefined) {
            alert.success("Result successfully submitted.");
            history.push(`/practice-results/${result.quizId}`);
            dispatch({
                type: SUBMIT_RESULT_RESET,
            });
        }
    }, [dispatch, rLoading, isSubmitted, alert]);

    
        
        const [isActive, setActive] = useState(false);
    
        const handleToggleMenu = () => {
            setActive(!isActive);
        };


    // function createMarkup() {
    //   return {__html: 'First &middot; Second'};
    // }

    return (
        <div className={isActive ? "menu-open" : null}>
            <section className="auth-dash-sec fullHt">
                <div className="left-navigations">
                    <div className="user-exam">
                        <div className="user-pros-exam">
                            <img
                                src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"}
                                alt="user"
                            />
                        </div>
                        <h4>
                            <b>Name</b>: {user.first_name} {user.last_name}
                        </h4>
                        <h4>
                            <i className="fas fa-calculator" onClick={() => setShowCalculator(true)} /> :{" "}
                            {localStorage.getItem("assessmentName")}
                        </h4>
                    </div>
                    <Modal title="Calculator" onClose={() => setShowCalculator(false)} show={showCalculator}>
                        {/*<p>This is modal body</p>*/}
                        <Calculator />
                    </Modal>
                    <div className="bot-examers">
                        {/*<h2>Subjects</h2>*/}
                        <div className="subject-tab">
                            <ul className="nav nav-tabs" role="tablist">
                                {questions?.map((subItem, i) => {
                                    if (subItem?.questions?.length > 0) {
                                        return (
                                            <Fragment key={i}>
                                                <li
                                                    role="presentation"
                                                    className={currentSubject === i ? "active" : ""}
                                                >
                                                    <a
                                                        href="#!"
                                                        // href={`#${subItem.subject.slug}`}
                                                        // aria-controls={`${subItem.subject.slug}`}
                                                        // role="tab"
                                                        // data-toggle="tab"
                                                        data-id={i}
                                                        onClick={handleSubjectType}
                                                    >
                                                        {subItem.subject.title}
                                                    </a>
                                                </li>
                                            </Fragment>
                                        );
                                    }
                                })}
                            </ul>

                            {/*
              <div className="tab-content">
                {questions[currentSubject] !== undefined &&
                  questions[currentSubject].subject !== undefined && (
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id={`${questions[currentSubject].subject.slug}`}
                    >
                      <div className="questions-opt">
                        <h5>Topic</h5>

                        <div className="boxes">
                          <input type="checkbox" id="box-1" />
                          <label for="box-1">Plant</label>

                          <input type="checkbox" id="box-2" checked />
                          <label for="box-2">Cell Biology</label>

                          <input type="checkbox" id="box-3" />
                          <label for="box-3">Other</label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
                  */}
                        </div>

                        <div className="number-lister">
                            {questions !== undefined &&
                                questions.length > 0 &&
                                questions[currentSubject].questions !== undefined &&
                                questions[currentSubject].questions.length > 0 &&
                                [...Array(questions[currentSubject].questions.length)].map(
                                    (e, i) => {
                                        let className = "";
                                        if (
                                            markForReviewed.indexOf(
                                                questions[currentSubject].questions[i]._id
                                            ) > -1
                                        ) {
                                            className = "markForReviewQuestion";
                                        } else if (
                                            answered.find(
                                                (obj) =>
                                                    obj.question ==
                                                    questions[currentSubject].questions[i]._id
                                            )
                                        ) {
                                            className = "markForAnsweredQuestion";
                                        } else if (currentQuestion === i) {
                                            className = "isActiveQuestion";
                                        }
                                        return (
                                            <Fragment key={i}>
                                                <a
                                                    className={className}
                                                    href="#!"
                                                    data-id={i}
                                                    onClick={handleFilterQuestion}
                                                >
                                                    {i + 1}
                                                </a>{" "}
                                            </Fragment>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                </div>
                <div class="nav-togg" onClick={handleToggleMenu}><span></span></div>

                <div className="right-auth-landing edit-pros">
                    <div className="main-view-content">
                        {assessmentType == "practice" && (
                            <div className="all-ourt-style timer w-80-cust legends-reviews clearfix">
                                <div className="pull-left legend-label">
                                    <div>Time Remaining</div> &nbsp;&nbsp;
                                    <div>
                                        <Countdown
                                            date={totalTime}
                                            //zeroPadTime={2}
                                            daysInHours={true}
                                            controlled={false}
                                            onTick={handleRemainigTime}
                                        //renderer={renderer}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <Fragment>
                            <div className="all-ourt-style w-80-cust legends-reviews clearfix">
                                <div className="pull-left legend-label">
                                    <p>
                                        <span style={{ background: "white" }} /> Un Answered
                                    </p>
                                    <p>
                                        <span style={{ background: "green" }} /> Answered
                                    </p>
                                    <p>
                                        <span style={{ background: "red" }} /> Marked for Review
                                    </p>
                                </div>
                                {questions !== undefined &&
                                    questions.length > 0 &&
                                    questions[currentSubject].questions !== undefined &&
                                    questions[currentSubject].questions.length > 0 && (
                                        <div className="pull-right">
                                            <div
                                                className="boxes"
                                                style={{ display: "inline-block" }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    name={`review-mark-${questions[currentSubject].questions[currentQuestion]._id}`}
                                                    id={`box-${questions[currentSubject].questions[currentQuestion]._id}`}
                                                    value={
                                                        questions[currentSubject].questions[currentQuestion]
                                                            ._id
                                                    }
                                                    onChange={handleMarkForReview}
                                                    checked={
                                                        markForReviewed.indexOf(
                                                            questions[currentSubject].questions[
                                                                currentQuestion
                                                            ]._id
                                                        ) > -1
                                                    }
                                                />
                                                <label
                                                    htmlFor={`box-${questions[currentSubject].questions[currentQuestion]._id}`}
                                                >
                                                    Mark for Review
                                                </label>
                                            </div>
                                            {/*:<span>(1) Question</span>*/}
                                        </div>
                                    )}
                            </div>

                            {questions !== undefined &&
                                questions.length > 0 &&
                                questions[currentSubject].questions.length > 0 && (
                                    <Fragment>
                                        {questions[currentSubject].questions[currentQuestion]
                                            .passage && (
                                                <div className="all-ourt-style w-80-cust work-info exam-set">
                                                    <div className="all-heads clearfix">

                                                        <h2>
                                                            <span>
                                                                Question  {parseInt(currentQuestion) + 1}
                                                                {". "}{" "}
                                                            </span>
                                                            <p>
                                                                {
                                                                    questions[currentSubject].questions[
                                                                        currentQuestion
                                                                    ].title
                                                                }
                                                            </p>
                                                        </h2>
                                                    </div>
                                                    <div className="exam-main-style">
                                                        <div className="answer-selection">
                                                            <div className="cust-redio">
                                                                {questions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].options !== undefined && (
                                                                        <Fragment>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        questions[currentSubject].questions[
                                                                                            currentQuestion
                                                                                        ].passage,
                                                                                }}
                                                                            />
                                                                        </Fragment>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        <div className="all-ourt-style w-80-cust work-info exam-set">
                                            <div className="all-heads clearfix">
                                                <h2 className="question-title">
                                                    {!questions[currentSubject].questions[currentQuestion]
                                                        .passage &&
                                                        <>   <span>
                                                            Question  {parseInt(currentQuestion) + 1}
                                                            {". "}{" "}
                                                        </span>
                                                            <p>
                                                                Title :- {" "}{" "}

                                                                {questions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].title}
                                                            </p>
                                                        </>


                                                        // <><p>
                                                        //     {/* {parseInt(currentQuestion) + 1}
                                                        // {". "}{" "} */}
                                                        //     Title :- {" "}{" "}
                                                        // </p>
                                                        //     <p>{questions[currentSubject].questions[
                                                        //         currentQuestion
                                                        //     ].title}
                                                        //     </p></>
                                                    }
                                                    {/* <p>
                                                        Q.No :-  {parseInt(currentQuestion) + 1}
                                                        {". "}{" "}
                                                    </p> */}
                                                    {/* <p
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                questions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].question,
                                                        }}
                                                    /> */}
                                                    {ReactHtmlParser(questions[currentSubject].questions[
                                                        currentQuestion
                                                    ].question)}
                                                </h2>
                                            </div>

                                            {questions[currentSubject].questions[
                                                currentQuestion
                                            ].logo !== undefined && questions[currentSubject].questions[
                                                currentQuestion
                                            ].logo !== null && (
                                                    <Fragment>
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_PUBLIC_URL +
                                                                "/assets/img/questions/" +
                                                                questions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].logo
                                                            }
                                                            alt={questions[currentSubject].questions[
                                                                currentQuestion
                                                            ].logo}
                                                            style={{ height: "200px" }}
                                                        />
                                                    </Fragment>

                                                )}

                                            <div className="exam-main-style">
                                                <div className="answer-selection">
                                                    <div className="cust-redio">
                                                        {questions[currentSubject].questions[currentQuestion].options !== undefined &&
                                                            questions[currentSubject].questions[currentQuestion].options.length > 0
                                                            ? questions[currentSubject].questions[currentQuestion].options?.map((answerOption, key) => {
                                                                if (answerOption.option !== "") {
                                                                    const indexId = key + 1;
                                                                    return (
                                                                        <Fragment key={indexId}>
                                                                            <label htmlFor={`option-${indexId}`}>
                                                                                <small>
                                                                                    {String.fromCharCode(65 + indexId - 1)}.
                                                                                </small>

                                                                                <input
                                                                                    type="radio"
                                                                                    name={`option-${questions[currentSubject].questions[currentQuestion]._id}`}
                                                                                    id={`option-${indexId}`}
                                                                                    value={answerOption._id}
                                                                                    //value={`${questions[currentQuestion]._id}-${answerOption._id}`}
                                                                                    // onClick={onChange}
                                                                                    onChange={(e) =>
                                                                                        onAnswerOptionChange(e)
                                                                                    }
                                                                                    data-questionid={
                                                                                        questions[currentSubject]
                                                                                            .questions[currentQuestion]._id
                                                                                    }
                                                                                    checked={
                                                                                        answered && answered?.find((obj) => obj?.question === questions[currentSubject]
                                                                                            .questions[currentQuestion]._id)?.answer === answerOption._id
                                                                                    }
                                                                                // checked={
                                                                                //     selections[answerOption._id] ===
                                                                                //     answerOption._id
                                                                                // }
                                                                                />
                                                                                {/* Render the option text once */}
                                                                                <span>
                                                                                    {ReactHtmlParser(answerOption.option)}{' '}

                                                                                    {rightAnswer !== undefined && showResults && (
                                                                                        <>
                                                                                            {/* Check if this is the selected answer */}
                                                                                            {answered?.find(
                                                                                                (obj) =>
                                                                                                    obj?.question === questions[currentSubject].questions[currentQuestion]._id
                                                                                            )?.answer === answerOption._id ? (
                                                                                                // Show check icon for correct answer or cancel icon for wrong answer
                                                                                                answerOption.option === rightAnswer ? (
                                                                                                    <label style={{ color: 'green', marginLeft: '8px' }}>
                                                                                                        <i className="fas fa-check" />
                                                                                                    </label>
                                                                                                ) : (
                                                                                                    <label style={{ color: 'red', marginLeft: '8px' }}>
                                                                                                        <i className="fas fa-times" />
                                                                                                    </label>
                                                                                                )
                                                                                            ) : null}

                                                                                            {/* Show check icon for the correct answer if not selected */}
                                                                                            {answerOption.option === rightAnswer &&
                                                                                                answered?.find(
                                                                                                    (obj) =>
                                                                                                        obj?.question === questions[currentSubject].questions[currentQuestion]._id
                                                                                                )?.answer !== answerOption._id && (
                                                                                                    <label style={{ color: 'green', marginLeft: '8px' }}>
                                                                                                        <i className="fas fa-check" />
                                                                                                    </label>
                                                                                                )}
                                                                                        </>
                                                                                    )}
                                                                                </span>

                                                                            </label>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            })
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                        </Fragment>

                        <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                            {assessmentType == "study" && (
                                <div className="pull-left">
                                    <button
                                        type="button"
                                        className="btn view-ans-btn"
                                        onClick={handleViewStudyAnswer}
                                    >
                                        {!showResults ? "View Answer" : "Hide Answer"}
                                    </button>
                                    {rightAnswer !== undefined && showResults && (
                                        <>
                                            <div className="correct-answer">Correct Answer :- {ReactHtmlParser(rightAnswer)}</div>
                                            <p className="correct-answer">Description :- {ReactHtmlParser(description)}</p>

                                            {/* <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        description,
                                                }}
                                            /> */}
                                            {/* {ReactHtmlParser(description)} */}
                                        </>

                                    )}
                                </div>
                            )}
                            <div className="pull-right">
                                {currentQuestion > 0 && (
                                    <button
                                        type="submit"
                                        className="btn nxt-prv"
                                        onClick={handlePreviousQuestionClick}
                                    >
                                        <i className="fas fa-angle-left" /> Prev
                                    </button>
                                )}

                                {currentQuestion < numberOfQuestions - 1 && (
                                    <button
                                        type="submit"
                                        className="btn nxt-prv"
                                        //disabled={!answer}
                                        onClick={handleNextQuestionClick}
                                    >
                                        Next <i className="fas fa-angle-right" />
                                    </button>
                                )}
                            </div>
                        </div>

                        {assessmentType !== "study" && (
                            <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                                <div className="pull-left" />
                                <div className="pull-right">
                                    <button
                                        type="submit"
                                        className="btn submit-ans-btn"
                                        // disabled={true}
                                        onClick={handleSubmitAnswer}
                                    >
                                        Submit Test
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

// Random component
const Completionist = () => <span>Time Up!</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <span>
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    }
};

export default ObjectiveQuestions;
